<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="parenting-4-1">育兒預備</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">寶寶發展</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜寶寶發展｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>0~6歲是兒童發展「關鍵期」</h3>
                        <p>所謂的兒童發展，是指孩子在成長時，不只身高體重會增加，「器官功能、心智能力」也會越變越成熟的過程；這個過程有一定的順序，也因此可以對照「兒童發展里程碑」來了解自己孩子的成長是否有按照自己的軌跡。雖然每個孩子都有自己的發展速率，有些人快、有些人慢，但在學齡前的「關鍵期」是代表孩子還能塑型的階段。是否能在成長過程中順利獲得未來所需的能力，就是要把握這段關鍵期。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>認識兒童發展里程碑的 6 大面向</h3>
                        <p>關鍵期中可以學習的能力包含哪些呢？像是視力、聽力、智力、肌肉等，都需要透過各種外界的刺激來幫助成長，而對照「兒童發展里程碑」，可以快速的了解孩子每個階段應該能達成的發展；如果有延遲或是出現特殊狀況，就能及早配合孩子的需要來進行「早期療育（早療）」。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>粗大動作：</h3>
                        <p>身體大肌肉的動作，像是手臂、大小腿以及軀幹，包含走、跑、跳、搬東西等需要用到大肌肉的動作都算。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>精細動作：</h3>
                        <p>通常是指手部的小肌肉動作，像是抓握、使用物品，例如剪裁、畫圖、寫字等，除了小肌肉的使用之外，也要配合身體、手眼的協調能力。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>認知：</h3>
                        <p>專注力、分析力、記憶力、企劃能力。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>語言：</h3>
                        <p>構音能力、正確用字的能力、表達自身意思的能力、理解溝通語境的能力。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>社交：</h3>
                        <p>能與他人相處、團隊合作的能力。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>情緒：</h3>
                        <p>對人事物有反應、投射情感、表達情緒的能力。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>兒童發展里程的重要性是什麼？</h3>
                        <p>簡單來說，就是「讓孩子全面發展自己的潛能」。孩子的健康並不是只有在外觀上要健壯高大、或是白白胖胖，而是要能有健全的身體質素發展、心理人格發展，達到身心健康，好好養成人格的狀態。
而透過對照兒童發展歷程，可以讓爸媽了解，孩子每個階段應該要達成的目標，以及想要達成這些目標，應該讓孩子做什麼樣的活動。除了避免限制孩子的發展之外，也能從中觀察到孩子的個性、氣質與潛力，讓孩子未來有更清楚的學習目標。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>兒童發展里程跟不上代表什麼問題？</h3>
                        <p>如果發現孩子跟不上發展進度，建議可以先帶孩子到小兒科神經科、小兒復健科諮詢專科醫師；另外，也可以檢視孩子在一個階段內，是否能完成正常發展兒童的 90%，如果達不到，代表可能真的有發展遲緩的問題，建議及早開始早療，讓孩子盡快跟上發展。</p>
                      </li>
                      <li data-aos="fade-up">
                        <p>參考來源：<a class="fs-5 text-danger" href="https://grow.heho.com.tw/archives/1713" target="_blank">Heho親子《0~6歲是兒童發展「關鍵期」！一次認識兒童發展、自我檢核表》</a></p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
